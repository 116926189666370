import React, { useState, useEffect } from "react";

import Carousel from "react-multi-carousel";

import "react-multi-carousel/lib/styles.css";

import banner1 from "assets/images/banner/forest-banner.png";

export default function SpecialDonationBanner() {
  const [isMobile, setIsMobile] = useState(false);

  const responsive = {
    desktop: {
      breakpoint: {
        max: 30000,
        min: 320,
      },
      items: 1,
    },
  };

  const ScrollInView = () => {
    if (document.getElementById("special-donation-form")) {
      document
        .getElementById("special-donation-form")
        .scrollIntoView({ top: 0, behavior: "smooth" });
    }
  };

  useEffect(() => {
    window.innerWidth < 768 ? setIsMobile(true) : setIsMobile(false);
  }, []);

  return (
    <div className="banner-section">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 banner-content-wrapper">
            <Carousel
              arrows={false}
              draggable={false}
              renderButtonGroupOutside={true}
              responsive={responsive}
            >
              <div className="img-wrapper">
                <div>
                  <img data-src={banner1} className="lazyload" alt="banner1" />
                  <div className="container banner-content">
                    <div className="row">
                      <div className="col-md-12 mb-5 text-center">
                        <span
                          className="btn   atn-btn"
                          onClick={() => ScrollInView()}
                        >
                          GIFT A TREE
                        </span>
                      </div>
                    </div>
                    <div className="row banner-content-row">
                      {/* <div className="col-sm-12 col-md-12 first-content">
                        <h3 style={{ fontSize: !isMobile ? "27px" : "" }}>
                          We are planting millions of trees across India
                        </h3>
  </div>*/}

                      <div className="col-sm-12 col-md-12 second-content">
                        <p>
                          We are delighted to share that Nishta is turning 1! As
                          we celebrate this special milestone, we are excited to
                          invite you all to join us in an initiative that holds
                          great significance for our family - the "Forest by
                          Heartfulness" project. This initiative aims to create
                          a greener space for our children, fostering a stronger
                          connection with nature and promoting environmental
                          consciousness.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  );
}

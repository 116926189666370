import React, { useState, useEffect } from "react";

import Carousel from "react-multi-carousel";

import "react-multi-carousel/lib/styles.css";

import intro1 from "assets/images/intro/intro1.jpg";
import intro2 from "assets/images/intro/intro2.jpg";
import intro3 from "assets/images/intro/intro3.jpg";
import intro4 from "assets/images/intro/intro4.jpg";
import intro5 from "assets/images/intro/intro5.jpg";
import intro6 from "assets/images/intro/intro6.png";

export default function Intro() {
  const responsive = {
    desktop: {
      breakpoint: {
        max: 30000,
        min: 320,
      },
      items: 1,
    },
  };

  const [isMobile, setIsMobile] = useState(false);
  const [mobileViewContent, setMobileViewContent] = useState(false);
  const [showReadMore, setShowReadMore] = useState(true);
  const [showReadLess, setShowReadLess] = useState(false);

  const ButtonGroup = ({ next, previous }) => {
    return (
      <div className="carousel-button-group">
        <div className="navigations">
          <button onClick={() => previous()} className="me-3">
            <i className="icon icon-tree-leaf-left"></i>
          </button>
          <button onClick={() => next()}>
            <i className="icon icon-tree-leaf-right"></i>
          </button>
        </div>
      </div>
    );
  };

  useEffect(() => {
    window.innerWidth < 768 ? setIsMobile(true) : setIsMobile(false);
  }, []);

  useEffect(() => {
    if (mobileViewContent === true) {
      setShowReadMore(false);
    }
  }, [mobileViewContent]);

  return (
    <div className="intro-section">
      <div className="section pt-0">
        <div className="container">
          <div className="row intro-section-row">
            <div className={showReadMore === false ? "col-md-6" : "col-md-6"}>
              <div className="section-title">
                {/* <h3 className="text-start">
                  <span className="green">Forests</span> <br /> by Heartfulness
  </h3>*/}
                <h4>
                  The "Forest by Heartfulness" Initiative: For a Greener Future
                </h4>
              </div>
              <div className="para-section">
                <p
                  style={{
                    marginBottom: isMobile === true ? "0px" : "25px",
                  }}
                >
                  Forest by Heartfulness is contributing to the conservation of
                  rare tree species worldwide through a comprehensive approach
                  that includes seed banking, habitat restoration, and
                  sustainable forestry practices. Additionally, their innovative
                  tissue cell lab facilitates the cultivation and propagation of
                  endangered tree species, ensuring their survival and promoting
                  biodiversity conservation efforts.How Can You Participate?
                </p>

                <p>
                  <b>Gift a Tree</b>: Your generous contributions will directly
                  support the planting of trees in the "Forest by Heartfulness"
                  project. Each tree symbolizes your love and care for Nishta as
                  she embarks on her journey of growth and discovery.
                </p>
                <p>
                  With your support, we can create a lasting impact on the
                  environment and ensure a greener and healthier space for our
                  children and generations to come.
                </p>
              </div>
            </div>

            <div
              className="col-md-6"
              // style={{ marginTop: isMobile === true ? "20px" : "0px" }}
              style={{
                marginBottom: isMobile === true ? "0px" : "25px",
              }}
            >
              <div className="intro-carousel">
                <Carousel
                  arrows={false}
                  autoPlaySpeed={3000}
                  draggable
                  infinite
                  keyBoardControl
                  minimumTouchDrag={80}
                  partialVisible
                  renderButtonGroupOutside={true}
                  swipeable
                  responsive={responsive}
                  customButtonGroup={<ButtonGroup />}
                >
                  <div className="img-wrapper">
                    <img
                      data-src={intro6}
                      className="lazyload"
                      alt="Daaji-planting-a-sapling"
                    />
                  </div>
                  <div className="img-wrapper">
                    <img
                      data-src={intro1}
                      className="lazyload"
                      alt="Daaji-planting-a-sapling"
                    />
                  </div>

                  <div className="img-wrapper">
                    <img
                      data-src={intro3}
                      className="lazyload"
                      alt="Children-with-placards"
                    />
                  </div>
                  <div className="img-wrapper">
                    <img
                      data-src={intro5}
                      className="lazyload"
                      alt="Planters-meditating"
                    />
                  </div>
                </Carousel>
              </div>
            </div>

            {showReadLess && isMobile && (
              <span
                className="special-donation-read-more"
                onClick={() => {
                  setMobileViewContent(false);
                  setShowReadMore(true);
                  setShowReadLess(false);
                }}
              >
                Read Less...
              </span>
            )}
            <div
              className="col-md-12"
              style={{ marginTop: isMobile ? "10px" : "" }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
}

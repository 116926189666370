import React, { useEffect, useRef } from "react";

import Logo from "assets/images/logo/forest-logo.png";

import { Navbar, Nav } from "react-bootstrap";

import { Link } from "gatsby";

const Header = ({ isSticky }) => {
  const ref = useRef(null);

  const scrollToSection = () => {
    document.querySelectorAll("a.scroller").forEach(function (a) {
      a.addEventListener("click", function (event) {
        if (event.target.getAttribute("href")) {
          event.preventDefault();
          const hash = event.target.getAttribute("href");
          const hashSection = hash.split("/#").pop();
          const scrollTarget = document.querySelector(`#${hashSection}`);
          const headerHeight = 60;
          if (scrollTarget) {
            window.scrollTo({
              top: scrollTarget.offsetTop - headerHeight,
              behavior: "smooth",
            });
          }
        }
      });
    });
  };

  useEffect(() => {
    scrollToSection();
    if (isSticky) {
      const handleScroll = () => {
        if (ref.current && ref.current.classList) {
          if (window.scrollY > 10) {
            ref.current.classList.add("sticky");
          } else {
            ref.current.classList.remove("sticky");
          }
        }
      };

      window.addEventListener("scroll", handleScroll);

      return () => {
        window.removeEventListener("scroll", () => handleScroll);
      };
    }
  }, []);

  return (
    <header
      id="header"
      className={`header-section ${isSticky ? "" : "sticky"}`}
      ref={ref}
    >
      <div className="top-menu">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <Navbar collapseOnSelect expand="lg">
                <Navbar.Brand>
                  <Link to="/">
                    <img src={Logo} alt="Logo" />
                  </Link>
                </Navbar.Brand>
                <div className="header-atn-btn">
                  <Link className="btn atn-btn" to="/#special-donation-section">
                    GIFT A TREE
                  </Link>
                </div>
                <Navbar.Toggle aria-controls="navbarScroll" />
                <Navbar.Collapse id="navbarScroll">
                  {isSticky ? (
                    <Nav className="mr-auto my-2 my-lg-0">
                      <Nav.Link href="/#home" className="scroller">
                        Home
                      </Nav.Link>
                      <Nav.Link href="/#introduction" className="scroller">
                        Introduction
                      </Nav.Link>
                      <Nav.Link
                        href="/#special-donation-section"
                        className="scroller"
                      >
                        Gift a Tree
                      </Nav.Link>
                      <Nav.Link href="/#donation-go" className="scroller">
                        Process
                      </Nav.Link>

                      <Nav.Link href="/#contact" className="scroller">
                        Contact
                      </Nav.Link>
                    </Nav>
                  ) : (
                    <Nav className="mr-auto my-2 my-lg-0">
                      <Link to="/" className="nav-link">
                        Home
                      </Link>
                      <Link to="/" className="nav-link">
                        Introduction
                      </Link>
                      <Link to="/" className="nav-link">
                        Gift a Tree
                      </Link>
                      <Link to="/" className="nav-link">
                        Process
                      </Link>

                      <Link to="/" className="nav-link">
                        Contact
                      </Link>
                    </Nav>
                  )}
                </Navbar.Collapse>
              </Navbar>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;

import * as yup from "yup";

import { identityNumberRegexes } from "forms/donationData";

export const specialDonationSchema = yup.object().shape({
  userName: yup
    .string()
    .required("This field is required")
    .max(60, "Only allowed maximum 60 characters"),
  email: yup
    .string()
    .email("Enter a valid email")
    .required("This field is required")
    .max(120, "Only allowed maximum 120 characters"),
  phone: yup
    .string()
    .typeError("This field is required")
    .required("This field is required"),
  city: yup
    .string()
    .required("This field is required")
    .max(60, "Only allowed maximum 60 characters"),
  state: yup
    .string()
    .required("This field is required")
    .max(60, "Only allowed maximum 60 characters"),
  address: yup
    .string()
    .required("This field is required")
    .max(120, "Only allowed maximum 120 characters"),
  pincode: yup
    .string()
    .required("This field is required")
    .max(10, "Only allowed maximum 10 character"),
  citizenship: yup
    .mixed()
    .typeError("Select a valid citizenship")
    .required("This field is required"),
  // currencyType: yup.string().required('This field is required'),
  amount: yup
    .number()
    .required("This field is required")
    .typeError("Please enter a valid amount")
    .positive("Please enter a positive number")
    .integer("Number only")
    .nullable(),
  customAmount: yup.mixed().notRequired(),
  saplingCount: yup.lazy((value) => {
    if (value < 30) {
      return (
        yup
          .number()
          // .min(1, 'Please contribute at least Thirty Tree')
          .min(1, "Please contribute at least")

          .typeError("Please contribute at least Thirty Tree")
      );
    }
    return yup.mixed().notRequired();
  }),
});

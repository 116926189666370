import React, { useState, useEffect } from "react";

import SEO from "components/seo/index";

import Layout from "../birthdaylayout/index";

import Banner from "components/birthday/Banner";

import Count from "components/birthday/Count";
import DonationsGoSection from "components/birthday/DonationsGoSection";
// import Projects from "components/coimbatore/Projects";
// import RecentPlantations from "components/birthday/recentplantation";
import DonationWidgetForm from "forms/BirthdayDonationWidgetForm";

import Intro from "components/birthday/Intro";

// schema
import { specialDonationSchema } from "forms/validations/birthdayDonation";

// data
import { amountSelect } from "forms/birthdayGiftingData";

import CurrencyService from "services/currency/currency.service";
// import SimpleReactLightbox from "simple-react-lightbox";
import SpentSection from "components/birthday/SpentSection";

import { ceil } from "lodash";

// markup
const CoimbatoreDonation = () => {
  const [amountObj, setAmountObj] = useState([]);
  const [baseAmountValue, setBaseAmountValue] = useState([]);
  const [isMobile, setIsMobile] = useState(false);

  const initBaseAmount = async () => {
    const cs = new CurrencyService();
    const csResponse = await cs.getINR();
    csResponse && csResponse.data && csResponse.data.rates;
    const currencyList = csResponse.data.rates;

    const baseAmount = 5000;

    setBaseAmountValue(baseAmount);

    const baseAmountConversion = amountSelect?.map((item) => {
      item.amount.INR = item.amount.INR;
      const rate = 0;

      Object.keys(currencyList).map((currencyType) => {
        const rate = ceil(item.amount.INR / currencyList[currencyType]);
        if (rate > 0) {
          item.amount[currencyType] = rate;
        }
      });
      if (item.type === "input") {
        item.amount.INR = "";
        item.amount.USD = "";
      }
      return item;
    });

    setAmountObj(baseAmountConversion);
    setAmountObj(amountSelect);
  };

  useEffect(() => {
    //   localStorage.setItem("donationFrom", 1);
    initBaseAmount();
  }, []);

  useEffect(() => {
    window.innerWidth < 768 ? setIsMobile(true) : setIsMobile(false);
  }, []);

  return (
    <>
      <SEO title="Forests By Heartfulness" />
      <Layout isSticky={true} wrapperClass="home">
        <section id="home">
          <Banner />
        </section>
        <section id="count-section">
          <Count />
        </section>
        <section id="introduction">
          <Intro />
        </section>
        <section
          id="special-donation-section"
          style={{ padding: isMobile === false ? "0px 20px" : "" }}
        >
          <DonationWidgetForm
            formType="donation"
            validationSchema={specialDonationSchema}
            amountSelect={amountObj}
            baseAmountValue={baseAmountValue}
          />
        </section>

        <section id="donation-go">
          <DonationsGoSection />
        </section>
        <section id="spent">
          <SpentSection />
        </section>

        {/* <SimpleReactLightbox>
          <section id="recent-plantation">
            <RecentPlantations />
          </section>
        </SimpleReactLightbox> */}
      </Layout>
    </>
  );
};

export default CoimbatoreDonation;
